import React, { useState, useEffect, useCallback, useMemo } from 'react';
import api from 'Modules/api.js';
import DefaultFormTemplate from 'Components/Templates/Common/DefaultFormTemplate';
import { setNestedJsonValue } from 'Modules/utility.js';

const formPartsConfig = [
  {
    labelText: 'ライター名',
    key: 'name',
    type: 'text',
  },
  {
    labelText: 'アイコン画像',
    key: 'image_key',
    type: 'file',
  },
]

const EditAuthor = (props) => {
  const [author, setAuthor] = useState({
    id: '',
    name: '',
    image_key: '',
    preview_source: '',
    created_at: '',
    updated_at: '',
  });

  useEffect(() => {
    api.httpGet(`/admin/authors/${props.match.params.id}`, (response) => {
      setAuthor(response.body);
    });
  }, [])

  const handleInputValue = (event) => {
    let value;
    if (event.target.type === 'file') {
      value = event.target.files[0]
    } else {
      value = event.target.value
    }
    setAuthor(setNestedJsonValue({...author}, event.target.name, value));
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData();
    data.append("author", JSON.stringify(author));
    data.append("file", author.image_key);
    api.patchToUpdate(
      props,
      event,
      `/admin/authors/${props.match.params.id}`,
      data,
      'ライターの更新に成功しました。',
      `/authors`,
      'ライターの更新に失敗しました。',
      `/authors/edit`,
      () => {
        // 成功時のコールバックでキャッシュを削除
        if ('caches' in window) {
          caches.keys().then((names) => {
            names.forEach((name) => caches.delete(name));
          });
        }
      }
    );
  }

  return(
    <DefaultFormTemplate
      pageTitle={`ライター:編集`}
      record={author}
      handleInputValue={handleInputValue}
      handleSubmit={handleSubmit}
      formPartsConfig={formPartsConfig}
    />
  );
}

export default EditAuthor